const en = {
    'Show more': 'Show more',
    'Show less': 'Show less',

    '4-week plan 80': '4-week plan',
    '12-week plan 80': '12-week plan',
    '24-week plan 80': '24-week plan',

    '1-Month full': '1-Month',
    '3-Month full': '3-Month',
    '6-Month full': '6-Month',
    '4-week plan USA': '4-week plan',
    '12-week plan USA': '12-week plan',
    '24-week plan USA': '24-week plan',
    '4-week plan USA discount': '4-week plan',
    '12-week plan USA discount': '12-week plan',
    '24-week plan USA discount': '24-week plan',

    '4-week plan USA pc_newreb_disc': '4-week plan',
    '12-week plan USA pc_newreb_disc': '12-week plan',
    '24-week plan USA pc_newreb_disc': '24-week plan',
    '4-week plan USA pc_newreb_disc discount': '4-week plan',
    '12-week plan USA pc_newreb_disc discount': '12-week plan',
    '24-week plan USA pc_newreb_disc discount': '24-week plan',

    'disclaimer_main': "{debitFor} after {days} days intro offer at the full price of {price}. {period}",
    'disclaimer_main_vat': "{debitFor} after {days} days intro offer at the full price of {price} (incl. VAT). {period}",
    'disclaimer_main_USA': "By clicking “Get my plan”, you agree to automatic renewal of subscription. {discountPeriod} at {price}, then at {priceFull}/{period}. Cancel by emailing <b>{supportEmail}</b>. Details in <b><a class=\"link\" href=\"{termsLink}\">Terms&nbsp;of&nbsp;Use</a></b>.",
    'disclaimer_compliance': `By clicking GET MY PLAN, I agree to pay <b>{price}</b> for my plan and that if I do not cancel 24 hours before the end of the <b>{period}</b> introductory plan, it will convert to a <b>{priceFull}</b> subscription and PawChamp will automatically charge my payment method the regular price <b>{priceFull}</b> every <b>{period}</b> thereafter until I cancel. I can cancel online <span class="underLine">by contacting</span> customer support team via email at {supportEmail}. <a class="link underLine" href="{termsLink}">Terms of Use.</a> The charge will appear on your bill as <span class="underLine">support.paw-champ.com</span><br><span>{supportPhoneNumber}</span>`,
    'disclaimer_compliance_hongkong': `I agree to pay <b>{price}</b> for my plan and that if I do not cancel 24 hours before the end of the <b>{period}</b> introductory plan, it will convert to a <b>{priceFull}</b> subscription and PawChamp will automatically charge my payment method the regular price <b>{priceFull}</b> every <b>{period}</b> thereafter until I cancel. I can cancel online <span class="underLine">by contacting</span> customer support team via email at {supportEmail}. <a class="link underLine" href="{termsLink}">Terms of Use.</a> The charge will appear on your bill as <span class="underLine">support.paw-champ.com</span><br><span>{supportPhoneNumber}</span>`,
    'disclaimer_compliance_full_price': `By clicking GET MY PLAN, I agree to pay <b>{price}</b> for my plan and that if I do not cancel 24 hours before the end of the <b>{period}</b> plan, it will convert to a <b>{priceFull}</b> subscription and PawChamp will automatically charge my payment method the regular price <b>{priceFull}</b> every <b>{period}</b> thereafter until I cancel. I can cancel online <span class="underLine">by contacting</span> customer support team via email at {supportEmail}. <a class="link underLine" href="{termsLink}">Terms of Use.</a> The charge will appear on your bill as <span class="underLine">support.paw-champ.com</span><br><span>{supportPhoneNumber}</span>`,
    'disclaimer_compliance_hongkong_full_price': `I agree to pay <b>{price}</b> for my plan and that if I do not cancel 24 hours before the end of the <b>{period}</b> plan, it will convert to a <b>{priceFull}</b> subscription and PawChamp will automatically charge my payment method the regular price <b>{priceFull}</b> every <b>{period}</b> thereafter until I cancel. I can cancel online <span class="underLine">by contacting</span> customer support team via email at {supportEmail}. <a class="link underLine" href="{termsLink}">Terms of Use.</a> The charge will appear on your bill as <span class="underLine">support.paw-champ.com</span><br><span>{supportPhoneNumber}</span>`,

    promoCodeFinalPostfix: 'final',

    monthShortNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    monthNames: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],

    magic: {
        '100_text': '{name}\'s plan is ready!',
    },

    productOnboarding: {
        passwordErrors: {
            passLength: "Password must contain at least 6 characters",
            passSymbols: "Password must contain latin letters, symbols, or numbers",
            passNotMatch: "Passwords must match",
        },

        emailErrors: {
            emailEmpty: "Please type your valid Email",
            emailIncorrect: "Incorrect Email format",
        },
    }
};

const es = {
    'Show more': 'Mostrar más',
    'Show less': 'Mostrar menos',

    'VAT': 'IVA',

    '4-week plan': 'De 4 semanas',
    '12-week plan': 'De 12 semanas',
    '24-week plan': 'De 24 semanas',
    '4-week plan USA': 'De 4 semanas',
    '12-week plan USA': 'De 12 semanas',
    '24-week plan USA': 'De 24 semanas',
    '4-week plan USA discount': 'De 4 semanas',
    '12-week plan USA discount': 'De 12 semanas',
    '24-week plan USA discount': 'De 24 semanas',

    'disclaimer_main': "Oferta de introducción {debitFor} después de {days} días al precio completo de {price}. {period}",
    'disclaimer_main_vat': "Oferta de introducción {debitFor} después de {days} días al precio completo de {price} (incluir IVA). {period}",
    'disclaimer_main_USA': "Al hacer clic en «Obtener mi plan», aceptas la renovación automática de la suscripción. {discountPeriod} a {price}, luego a {priceFull}/{period}. Cancela enviando un correo electrónico a <b>{supportEmail}</b>. Detalles en los <b><a class=\"link\" href=\"{termsLink}\">Términos&nbsp;de&nbsp;uso</a></b>.",

    // first visit disclamer
    'Extended biweekly': 'quincenal extendida',
    'Extended monthly': 'extendido mensualmente',
    'Billed monthly.': 'Facturado mensualmente.',
    'Billed quarterly.': 'Facturación trimestral.',
    'Billed for 6 months.': 'Facturado por 6 meses.',

    'month': 'mes',
    'quarterly': 'trimestral',
    '6 months': '6 meses',
    'First month': 'Primer Mes',
    'First 3 months': 'Primeros 3 Meses',
    'First 6 months': 'Primeros 6 Meses',

    // some elements
    'Last Chance': 'Última oportunidad',
    '74% off': '74% Rabatt',
    // age
    puppy: 'cachorro',
    adolescent: 'Perro adolescente',
    adult: 'Perro adulto',
    senior: 'Perro mayor',

    he: 'emocionado',
    she: 'emocionada',

    // breeds
    'French Bulldog': 'Bulldog Francés',
    'German Shepherd': 'Pastor Alemán',
    'Mixed breed': 'Raza mestiza',
    'Cocker Spaniel': 'Cocker',

    // problems
    'Excessive energy': 'Energía excesiva',
    Aggression: 'Agresión',
    'Leash pulling': 'Tracción de la correa',
    'Separation anxiety': 'Ansiedad de separación',
    'Excessive barking': 'Ladridos excesivos',
    'Destructive behavior': 'Comportamiento destructivo',
    'House soiling': 'Ensucia la casa',

    // goals
    'Learn puppy basics': 'Conceptos básicos de cachorros',
    'Improve obedience': 'Mejorar la obediencia',
    'Learn useful commands': 'Que aprenda comandos útiles',
    'Build a strong bond with doggo': 'Vínculo fuerte con mi perro',
    'Solve behavioral': 'Resolver problemas de comportamiento',
    'Basic commands': 'Aprender comandos útiles',
    'Learn advanced commands': 'Crear un vínculo fuerte con mi perro',
    'Overcome reactivity': 'Superar la reactividad',
    'Decrease aggression': 'Disminuir la agresión',

    // gender female questions
    '¡No, él es un amante de la comida!': 'No, ella es una amante de la comida!',
    'Sí, le gustaría que lo acaricionen las 24 horas, los 7 días de la semana': 'Sí, le gustaría que la acariciaran las 24 horas del día, los 7 días de la semana',
    '¡Sí, él lo ama!': 'Si, a ella le encanta',
    'Sí, él lo ama': 'Si, a ella le encanta',
    '¿Planeas castrar a tu cachorro?': '¿Planeas esterilizar a tu cachorro?',
    'No, me presta atención': 'No, ella me presta atención',
    'Si, me ignora': 'Si, ella me ignora',
    'No, él me presta atención': 'No, ella me presta atención',
    'No, no le gusta que lo toquen': 'No, a ella le gusta que no la toquen',

    Wedding: 'Boda',
    Vacation: 'Vacaciones',
    'Birth child': 'Nacimiento de un niño',
    'Pet arrival': 'Nueva llegada de mascotas',
    Relocation: 'Reubicación',
    'Personal Goal': 'Meta personal',
    Other: 'Otro',
    Skip: 'Ningún evento especial',

    // password errors
    'Passwords must match': 'Las contraseñas deben coincidir',
    'Password must contain at least 6 characters': 'La contraseña debe contener al menos 6 caracteres',
    'Current user already has password': 'El usuario actual ya tiene una contraseña',
    'Please enter email of current user': 'Por favor, ingresa el correo electrónico del usuario actual',
    'Wrong E-mail or password': 'Correo electrónico o contraseña incorrectos',

    // email errors
    'Sorry. Something went wrong. Try again later': 'Lo siento. Algo salió mal. Inténtalo de nuevo más tarde',
    'Please, type your valid E-mail. Field couldn’t be empty': 'Por favor, ingresa tu correo electrónico válido. El campo no puede estar vacío',
    'Please, type your valid E-mail': 'Por favor, ingresa tu correo electrónico válido',
    'User with this email already exists': 'Ya existe un usuario con este correo electrónico',

    // card errors
    'Error occurred. Insufficient funds': 'Ocurrió un error. Fondos insuficientes',
    'Error occurred. Payment amount limit exceeded': 'Ocurrió un error. Se excedió el límite de cantidad de pago',
    'Error occurred. Debit card is not supported': 'Ocurrió un error. La tarjeta de débito no es compatible',
    'Error occurred. Card brand is not supported': 'Ocurrió un error. La marca de la tarjeta no es compatible',
    'Error occurred. Invalid CVV2 code': 'Ocurrió un error. Código CVV2 inválido',
    'Error occurred. Invalid card number': 'Ocurrió un error. Número de tarjeta inválido',
    'Error occurred. Invalid expiration date': 'Ocurrió un error. Fecha de vencimiento inválida',
    'Error occurred. 3D secure verification failed': 'Ocurrió un error. Falló la verificación 3D Secure',
    'Error occurred. Please try another card or payment method': 'Ocurrió un error. Por favor, prueba con otra tarjeta o método de pago',
    'Error occurred. Please try again or try another card/payment method': 'Ocurrió un error. Inténtalo nuevamente o prueba con otra tarjeta/método de pago',

    promoCodeFinalPostfix: 'final',

    monthShortNames: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],

    magic: {
        '100_text': 'El plan de {name} esta listo!'
    }
};

const pt = {
    'Show more': 'Mostrar mais',
    'Show less': 'Mostrar menos',

    'VAT': 'IVA',

    '4-week plan': '4 semanas',
    '12-week plan': '12 semanas',
    '24-week plan': '24 semanas',
    '4-week plan USA': '4 semanas',
    '12-week plan USA': '12 semanas',
    '24-week plan USA': '24 semanas',
    '4-week plan USA discount': '4 semanas',
    '12-week plan USA discount': '12 semanas',
    '24-week plan USA discount': '24 semanas',

    'disclaimer_main': "Oferta de introdução {debitFor} após {days} dias pelo preço total de {price}. {period}",
    'disclaimer_main_vat': "Oferta de introdução {debitFor} após {days} dias pelo preço total de {price} (incluir IVA). {period}",
    'disclaimer_main_USA': "Ao clicar em «Obter meu plano», você aceita a renovação automática da assinatura. {discountPeriod} por {price}, depois por {priceFull}/{period}. Cancele enviando um e-mail para <b>{supportEmail}</b>. Detalhes nos <b><a class=\"link\" href=\"{termsLink}\">Termos&nbsp;de&nbsp;Uso</a></b>.",

    // first visit disclamer
    'Extended biweekly': 'estendida quinzenalmente',
    'Extended monthly': 'estendida mensalmente',
    'Billed monthly.': 'Faturado mensalmente.',
    'Billed quarterly.': 'Faturado trimestralmente.',
    'Billed for 6 months.': 'Faturado por 6 meses.',

    'month': 'mês',
    'quarterly': 'trimestral',
    '6 months': '6 meses',
    'First month': 'Primeiro Mês',
    'First 3 months': 'Primeiros 3 Meses',
    'First 6 months': 'Primeiros 6 Meses',

    // some elements
    'Last Chance': 'Última chance',

    // age
    puppy: 'filhote',
    adolescent: 'Cão adolescente',
    adult: 'Cão adulto',
    senior: 'Cachorro sênior',

    'he': 'ele',
    'she': 'ela',

    // breeds
    'French Bulldog': 'Bulldog Fancês',
    'German Shepherd': 'Pastor Alemão',
    'Mixed breed': 'Raça misturada',

    // problems
    'Excessive energy': 'Energia excessiva',
    Aggression: 'Agressão',
    'Leash pulling': 'Puxando a coleira',
    'Separation anxiety': 'Ansiedade de separação',
    'Excessive barking': 'Latidos excessivos',
    "Destructive behavior": 'Comportamento destrutivo',
    'House soiling': 'Sujeira na casa',

    'Learn useful commands': 'Aprenda comandos úteis',
    'Build a strong bond with doggo': 'Construa um forte vínculo com o cão',
    'Puppy basics': 'Noções básicas sobre filhotes',

    // events
    Vacation: 'Férias',
    Wedding: 'Casamento',
    'Birth child': 'Nascimento de uma criança',
    'Pet arrival': 'Chegada de novo animal de estimação',
    Relocation: 'Mudança',
    'Personal Goal': 'Objetivo Pessoal',

    // gender female questions
    'Sim, ele me ignora': 'Sim, ela me ignora',
    'Não, ele presta atenção em mim': 'Não, ela presta atenção em mim',
    'Não, ele adora comida!': 'Não, ela adora comida!',
    'Entenda ele melhor': 'Entenda ela melhor',
    'Sim, ele adora!': 'Sim, ela adora!',
    'Sim, ele gostaria de ser acariciado 24/7': 'Sim, ela gostaria de ser acariciada 24/7',
    'Não, ele não gosta de ser tocado': 'Não, ela não gosta de ser tocada',
    'Sim, ele adora': 'Sim, ela adora',
    'Ele pergunta brincar com você?': 'Ela pergunta brincar com você?',

    // goals
    'Improve obedience': 'Melhorar a obediência',
    'Solve behavioral': 'Resolver problemas comportamentais',
    'Basic commands': 'Comandos básicos',
    'Learn advanced commands': 'Aprenda comandos avançados',
    'Learn puppy basics': 'Aprender o básico do cachorrinho',
    'Overcome reactivity': 'Superar a reatividade',
    'Decrease aggression': 'Diminuir a agressividade',

    // password errors
    'Passwords must match': 'As senhas devem corresponder',
    'Password must contain at least 6 characters': 'A senha deve conter pelo menos 6 caracteres',
    'Current user already has password': 'O utilizador atual já tem uma senha',
    'Please enter email of current user': 'Por favor, insira o email do utilizador atual',
    'Wrong E-mail or password': 'Email ou senha incorretos',

    // email errors
    'Sorry. Something went wrong. Try again later': 'Desculpe. Algo correu mal. Tente novamente mais tarde',
    'Please, type your valid E-mail. Field couldn’t be empty': 'Por favor, insira o seu email válido. O campo não pode ficar vazio',
    'Please, type your valid E-mail': 'Por favor, insira o seu email válido',
    'User with this email already exists': 'Já existe um utilizador com este email',

    // card errors
    'Error occurred. Insufficient funds': 'Ocorreu um erro. Fundos insuficientes',
    'Error occurred. Payment amount limit exceeded': 'Ocorreu um erro. Limite de valor de pagamento excedido',
    'Error occurred. Debit card is not supported': 'Ocorreu um erro. Cartão de débito não suportado',
    'Error occurred. Card brand is not supported': 'Ocorreu um erro. Marca do cartão não suportada',
    'Error occurred. Invalid CVV2 code': 'Ocorreu um erro. Código CVV2 inválido',
    'Error occurred. Invalid card number': 'Ocorreu um erro. Número de cartão inválido',
    'Error occurred. Invalid expiration date': 'Ocorreu um erro. Data de validade inválida',
    'Error occurred. 3D secure verification failed': 'Ocorreu um erro. Falha na verificação de segurança 3D',
    'Error occurred. Please try another card or payment method': 'Ocorreu um erro. Por favor, tente outro cartão ou método de pagamento',
    'Error occurred. Please try again or try another card/payment method': 'Ocorreu um erro. Por favor, tente novamente ou tente outro cartão/método de pagamento',

    promoCodeFinalPostfix: 'final',

    monthShortNames: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],

    magic: {
        '100_text': 'O plano do {name} está pronto!'
    }
};

const de = {
    'Show more': 'Mehr anzeigen',
    'Show less': 'Weniger anzeigen',

    'VAT': 'MWSt',

    '4-week plan': '4-Wochen-Plan',
    '12-week plan': '12-Wochen-Plan',
    '24-week plan': '24-Wochen-Plan',
    '4-week plan USA': '4-Wochen-Plan',
    '12-week plan USA': '12-Wochen-Plan',
    '24-week plan USA': '24-Wochen-Plan',
    '4-week plan USA discount': '4-Wochen-Plan',
    '12-week plan USA discount': '12-Wochen-Plan',
    '24-week plan USA discount': '24-Wochen-Plan',

    'disclaimer_main': "{debitFor} verlängertes Einführungsangebot nach {days} Tagen zum vollen Preis von {price}. {period}",
    'disclaimer_main_vat': "{debitFor} verlängertes Einführungsangebot nach {days} Tagen zum vollen Preis von {price} (inkl. MWSt). {period}",
    'disclaimer_main_USA': "Wenn Sie auf „Meinen plan bekommen“ klicken, stimmen Sie der automatischen Verlängerung des Abonnements zu. {discountPeriod} für {price}, danach für {priceFull}/{period}. Kündigung per E-Mail an <b>{supportEmail}</b>. Details in den <b><a class=\"link\" href=\"{termsLink}\">Nutzungsbedingungen</a></b>.",

    // first visit disclamer
    'Extended biweekly': 'Zweiwöchentlich',
    'Extended monthly': 'Monatlich',
    'Billed monthly.': 'Wird monatlich abgerechnet.',
    'Billed quarterly.': 'Wird vierteljährlich abgerechnet.',
    'Billed for 6 months.': 'In Rechnung gestellt für 6 Monate.',

    'month': 'monat',
    'quarterly': 'vierteljährlich',
    '6 months': '6 monate',
    'First month': 'Erster Monat',
    'First 3 months': 'Erste 3 Monate',
    'First 6 months': 'Erste 6 Monate',

    // some elements
    'Last Chance': 'Letzte Möglichkeit',

    // age
    puppy: 'Welpe',
    adolescent: 'Junger Hund',
    adult: 'Erwachsener Hund',
    senior: 'Älterer Hund',

    'he': 'er',
    'she': 'sie',

    // user important events
    Vacation: 'Urlaub',
    Wedding: 'Hochzeit',
    'Birth child': 'Geburt eines Kindes',
    'Pet arrival': 'Ein neues Haustier',
    Relocation: 'Umzug',
    'Personal Goal': 'Persönliches Ziel',
    Other: 'Anderes',

    // breeds
    'French Bulldog': 'Französische Bulldogge',
    'German Shepherd': 'Deutscher Schäferhund',
    'Mixed breed': 'Gemischte Rasse',
    'American Pit Bull Terrier': 'Amerikanischer Pitbull - Terrier',
    'Staffordshire Bull Terrier': 'Staffordshire Bullterrier',

    // problems
    'Excessive energy': 'Übermäßige Energie',
    Aggression: 'Aggressivität',
    'Leash pulling': 'Ziehen an der Leine',
    'Separation anxiety': 'Trennungsangst',
    'Destructive behavior': 'Zerstörerisches Verhalten',
    'Excessive barking': 'Übermäßiges Bellen',
    'House soiling': 'Verunreinigung der Wohnung',
    'Learn useful commands': 'Lernen Sie nützliche Befehle',

    // gender female questions
    'Ja, er ignoriert mich': 'Ja, sie hat mich ignoriert',
    'Nein, er beachtet mich': 'Nein, sie schenkt mir Aufmerksamkeit',
    'Nein, er isst gerne!': 'Nein, sie isst gern!',
    'Ja, er liebt es!': 'Ja, sie liebt es!',
    'Ja, er möchte rund um die Uhr gestreichelt werden': 'Ja, sie möchte rund um die Uhr gestreichelt werden',
    'Ja, er liebt es': 'Ja, sie liebt es!',
    'Nein, er möchte lieber unbehelligt bleiben': 'Nein, sie möchte lieber unbehelligt bleiben',

    // goals
    'Improve obedience': 'Gehorsam fördern',
    'Solve behavioral': 'Verhaltensauffälligkeiten lösen',
    'Basic commands': 'Grundlegende Befehle',
    'Learn advanced commands': 'Lernen Sie erweiterte Befehle',
    'Build a strong bond with doggo': 'Eine starke Bindung zum Hund aufbauen',
    'Learn puppy basics': 'Lernen Sie die Grundlagen des Welpen',
    'Overcome reactivity': 'Reaktivität überwinden',
    'Decrease aggression': 'Aggression verringern',

    // password errors
    'Passwords must match': 'Die Passwörter müssen identisch sein',
    'Password must contain at least 6 characters': 'Das Passwort muss mindestens 6 Zeichen lang sein',
    'Current user already has password': 'Der aktuelle Benutzer hat bereits ein Passwort',
    'Please enter email of current user': 'Bitte geben Sie die E-Mail-Adresse des aktuellen Benutzers ein',
    'Wrong E-mail or password': 'Falsche Email oder Passwort',

    // email errors
    'Sorry. Something went wrong. Try again later': 'Entschuldigung. Etwas ist schief gelaufen. Versuchen Sie es später erneut',
    'Please, type your valid E-mail. Field couldn’t be empty': 'Bitte geben Sie Ihre gültige E-Mail-Adresse ein. Das Feld darf nicht leer sein',
    'Please, type your valid E-mail': 'Bitte geben Sie Ihre gültige E-Mail-Adresse ein',
    'User with this email already exists': 'Ein Benutzer existiert bereits mit dieser E-Mail-Adresse',

    // card errors
    'Error occurred. Please try again later': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal',
    'Error occurred. Insufficient funds': 'Ein Fehler ist aufgetreten. Unzureichende Mittel',
    'Error occurred. Payment amount limit exceeded': 'Ein Fehler ist aufgetreten. Zahlungsbetragslimit überschritten',
    'Error occurred. Debit card is not supported': 'Ein Fehler ist aufgetreten. Debitkarten werden nicht unterstützt',
    'Error occurred. Card brand is not supported': 'Ein Fehler ist aufgetreten. Kartenmarke wird nicht unterstützt',
    'Error occurred. Invalid CVV2 code': 'Ein Fehler ist aufgetreten. Ungültiger CVV2-Code',
    'Error occurred. Invalid card number': 'Ein Fehler ist aufgetreten. Ungültige Kartennummer',
    'Error occurred. Invalid expiration date': 'Ein Fehler ist aufgetreten. Ungültiges Ablaufdatum',
    'Error occurred. 3D secure verification failed': 'Ein Fehler ist aufgetreten. Die 3D-Secure-Überprüfung ist fehlgeschlagen',
    'Error occurred. Please try another card or payment method': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es mit einer anderen Karte oder Zahlungsmethode',
    'Error occurred. Please try again or try another card/payment method': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder versuchen Sie es mit einer anderen Karte/Zahlungsmethode',

    promoCodeFinalPostfix: 'finale',

    monthShortNames: ['jan', 'feb', 'mär', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dez'],

    magic: {
        '100_text': '{name} Plan ist fertig!'
    }
};

const fr = {
    'Show more': 'Afficher plus',
    'Show less': 'Afficher moins',

    'VAT': 'TVA',

    '4-week plan': '4 semaines',
    '12-week plan': '12 semaines',
    '24-week plan': '24 semaines',
    '4-week plan USA': '4 semaines',
    '12-week plan USA': '12 semaines',
    '24-week plan USA': '24 semaines',
    '4-week plan USA discount': '4 semaines',
    '12-week plan USA discount': '12 semaines',
    '24-week plan USA discount': '24 semaines',

    'disclaimer_main': "Prolongé {debitFor} après une offre d\'introduction de {days} jours au prix total de {price}. {period}",
    'disclaimer_main_vat': "Prolongé {debitFor} après une offre d\'introduction de {days} jours au prix total de {price} (TVA incluse). {period}",
    'disclaimer_main_USA': "En cliquant sur «&nbsp;Obtenir mon plan&nbsp;», vous acceptez le renouvellement automatique de l'abonnement. {discountPeriod} à {price}, puis à {priceFull}/{period}. Annulez en envoyant un e-mail à <b>{supportEmail}</b>. Détails dans les <b><a class=\"link\" href=\"{termsLink}\">Conditions&nbsp;d'utilisation</a></b>.",

    // first visit disclamer
    'Extended biweekly': 'bimensuellement',
    'Extended monthly': 'mensuellement',
    'Billed monthly.': 'Facturé mensuellement.',
    'Billed quarterly.': 'Facturé trimestriellement.',
    'Billed for 6 months.': 'Facturé pour 6 mois.',

    'month': 'mois',
    'quarterly': 'trimestriel',
    '6 months': '6 mois',
    'First month': 'Premier Mois',
    'First 3 months': 'Premiers 3 Mois',
    'First 6 months': 'Premiers 6 Mois',

    // first visit disclamer
    'Extended biweekly after 30 days intro offer at the full price of ': 'Prolongé bimensuellement après une offre d\'introduction de 30 jours au prix total de ',
    'Extended monthly after 90 days intro offer at the full price of ': 'Prolongé mensuellement après une offre d\'introduction de 90 jours au prix total de ',
    'Extended monthly after 180 days intro offer at the full price of ': 'Prolongé mensuellement après une offre d\'introduction de 180 jours au prix total de ',
    ' (incl. VAT). Billed monthly.': ' (TVA incluse). Facturé mensuellement.',
    ' (incl. VAT). Billed quarterly.': ' (TVA incluse). Facturé trimestriellement.',
    ' (incl. VAT). Billed for 6 months.': ' (TVA incluse). Facturé pour 6 mois.',
    ' Billed monthly.': ' Facturé mensuellement.',
    ' Billed quarterly.': ' Facturé trimestriellement.',
    ' Billed for 6 months.': ' Facturé pour 6 mois.',

    // some elements
    'Last Chance': 'Dernière chance',

    // age
    puppy: 'chiot',
    adolescent: 'Adolescent',
    adult: 'Chien adulte',
    senior: 'Chien senior',

    'he': 'il',
    'she': 'elle',

    // user important events
    Vacation: 'Vacances',
    Wedding: 'Mariage',
    'Birth child': 'Naissance d\'un enfant',
    'Pet arrival': 'Nouvelle arrivée pour animaux de compagnie',
    Relocation: 'Déménagement',
    'Personal Goal': 'Objectif personnel',
    Other: 'Autre',

    // breeds
    'French Bulldog': 'Bulldog français',
    'German Shepherd': 'Berger allemand',
    'Mixed breed': 'Race mixte',
    'American Pit Bull Terrier': 'American Pit Bull Terrier',
    'Staffordshire Bull Terrier': 'Staffordshire Bull Terrier',
    'Cocker Spaniel': 'Cocker',

    // problems
    'Excessive energy': 'Énergie excessive',
    Aggression: 'Agression',
    'Leash pulling': 'Tirer sur la laisse',
    'Separation anxiety': 'Anxiété de séparation',
    'Excessive barking': 'Aboiement excessif',
    'Destructive behavior': 'Comportement destructeur',
    'House soiling': 'Maison salissante',
    'Ja, er ignoriert mich': 'Ja, sie hat mich ignoriert',
    'Oui, il m\'ignore': 'Oui, elle m\'ignore',
    'Non, il fait attention à moi': 'Non, elle fait attention à moi',
    'Non, c\'est un amoureux de la nourriture !': 'Non, c\'est une amatrice de nourriture !',
    'Oui, il adore ça !': 'Oui, elle adore ça !',
    'Oui, il adore ça': 'Oui, elle adore ça',
    'Oui, il aimerait être caressé 24/7': 'Oui, elle aimerait être caressée 24/7',
    'Non, il aime être intact': 'Non, elle aime être intacte',

    // gender female questions
    'Nein, er beachtet mich': 'Nein, sie schenkt mir Aufmerksamkeit',
    'Nein, er isst gerne!': 'Nein, sie isst gern!',
    'Ja, er liebt es!': 'Ja, sie liebt es!',
    'Ja, er möchte rund um die Uhr gestreichelt werden': 'Ja, sie möchte rund um die Uhr gestreichelt werden',
    'Ja, er liebt es': 'Ja, sie liebt es!',
    'Nein, er möchte lieber unbehelligt bleiben': 'Nein, sie möchte lieber unbehelligt bleiben',
    'Improve obedience': 'Améliorer l\'obéissance',

    // goals
    'Solve behavioral': 'Verhaltensauffälligkeiten lösen',
    'Learn useful commands': 'Apprendre des ordres utiles',
    'Build a strong bond with doggo': 'Construisez un lien fort avec Doggo',
    'Learn puppy basics': 'Bases de chiots',
    'Overcome reactivity': 'Surmonter la réactivité',
    'Decrease aggression': 'Diminuer l\'agressivité',

    // password errors
    'Passwords must match': 'Les mots de passe doivent correspondre',
    'Password must contain at least 6 characters': 'Le mot de passe doit contenir au moins 6 caractères',
    'Current user already has password': 'L\'utilisateur actuel a déjà un mot de passe',
    'Please enter email of current user': 'Veuillez saisir l\'e-mail de l\'utilisateur actuel',
    'Wrong E-mail or password': 'Mauvais e-mail ou mot de passe',

    // email errors
    'Sorry. Something went wrong. Try again later': 'Désolé. Quelque chose s\'est mal passé. Réessayez plus tard',
    'Please, type your valid E-mail. Field couldn’t be empty': 'Veuillez saisir votre e-mail valide. Le champ ne peut pas être vide',
    'Please, type your valid E-mail': 'S\'il vous plaît, tapez votre e-mail valide',
    'User with this email already exists': 'Utilisateur avec ce courriel existe déjà',

    // card errors
    'Error occurred. Please try again later': 'Error occurred. Please try again later',
    'Error occurred. Insufficient funds': 'Erreur est survenue. Fonds insuffisants',
    'Error occurred. Payment amount limit exceeded': 'Erreur est survenue. Limite du montant du paiement dépassée',
    'Error occurred. Debit card is not supported': 'Erreur est survenue. La carte de débit n\'est pas prise en charge',
    'Error occurred. Card brand is not supported': 'Erreur est survenue. La marque de la carte n\'est pas prise en charge',
    'Error occurred. Invalid CVV2 code': 'Ein Fehler ist aufgetreten. Ungültiger CVV2-Code',
    'Error occurred. Invalid card number': 'Ein Fehler ist aufgetreten. Ungültige Kartennummer',
    'Error occurred. Invalid expiration date': 'Ein Fehler ist aufgetreten. Ungültiges Ablaufdatum',
    'Error occurred. 3D secure verification failed': 'Ein Fehler ist aufgetreten. Die 3D-Secure-Überprüfung ist fehlgeschlagen',
    'Error occurred. Please try another card or payment method': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es mit einer anderen Karte oder Zahlungsmethode',
    'Error occurred. Please try again or try another card/payment method': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder versuchen Sie es mit einer anderen Karte/Zahlungsmethode',

    promoCodeFinalPostfix: 'final',

    monthShortNames: ['janv', 'fév', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],

    magic: {
        '100_text': '{name} plan pour chiens est prêt&nbsp;!'
    }
};

const it = {
    'Show more': 'Mostra di più',
    'Show less': 'Mostra di meno',

    'VAT': 'IVA',

    '4-week plan': 'Di 4 settimane',
    '12-week plan': 'Di 12 settimane',
    '24-week plan': 'Di 24 settimane',
    '4-week plan USA': 'Di 4 settimane',
    '12-week plan USA': 'Di 12 settimane',
    '24-week plan USA': 'Di 24 settimane',
    '4-week plan USA discount': 'Di 4 settimane',
    '12-week plan USA discount': 'Di 12 settimane',
    '24-week plan USA discount': 'Di 24 settimane',

    'disclaimer_main': "Offerta introduttiva {debitFor} dopo {days} giorni al prezzo pieno di {price}. {period}",
    'disclaimer_main_vat': "Offerta introduttiva {debitFor} dopo {days} giorni al prezzo pieno di {price} (inclusa IVA). {period}",
    'disclaimer_main_USA': "Cliccando su «Ottieni il mio piano», accetti il rinnovo automatico dell'abbonamento. {discountPeriod} a {price}, poi a {priceFull}/{period}. Annulla inviando un'e-mail a <b>{supportEmail}</b>. Dettagli nei <b><a class=\"link\" href=\"{termsLink}\">Termini&nbsp;di&nbsp;utilizzo</a></b>.",

    // first visit disclaimer
    'Extended biweekly': 'quindicinale estesa',
    'Extended monthly': 'mensile esteso',
    'Billed monthly.': 'Fatturato mensilmente.',
    'Billed quarterly.': 'Fatturazione trimestrale.',
    'Billed for 6 months.': 'Fatturato per 6 mesi.',

    'month': 'mese',
    'quarterly': 'trimestrale',
    '6 months': '6 mesi',
    'First month': 'Primo Mese',
    'First 3 months': 'Primi 3 Mesi',
    'First 6 months': 'Primi 6 Mesi',

    // some elements
    'Last Chance': 'Ultima occasione',
    '74% off': '74% di sconto',

    // age
    puppy: 'cucciolo',
    adolescent: 'Cane adolescente',
    adult: 'Cane adulto',
    senior: 'Cane anziano',

    he: 'eccitato',
    she: 'eccitata',

    // breeds
    'French Bulldog': 'Bulldog Francese',
    'German Shepherd': 'Pastore Tedesco',
    'Mixed breed': 'Razza mista',
    'Cocker Spaniel': 'Cocker',

    // problems
    'Excessive energy': 'Energia eccessiva',
    Aggression: 'Aggressività',
    'Leash pulling': 'Tirare al guinzaglio',
    'Separation anxiety': 'Ansia da separazione',
    'Excessive barking': 'Abbaio eccessivo',
    'Destructive behavior': 'Comportamento distruttivo',
    'House soiling': 'Sporcizia in casa',

    // goals
    'Learn puppy basics': 'Imparare le basi del cucciolo',
    'Improve obedience': 'Migliorare l’obbedienza',
    'Learn useful commands': 'Imparare comandi utili',
    'Build a strong bond with doggo': 'Creare un forte legame con il cane',
    'Solve behavioral': 'Risolvi problemi comportamentali',
    'Basic commands': 'Impara comandi utili',
    'Learn advanced commands': 'Crea un forte legame con il mio cane',
    'Overcome reactivity': 'Supera la reattività',
    'Decrease aggression': 'Diminuisci l’aggressività',

    // gender female questions
    'Sì, lui mi ignora': 'Sì, lei mi ignora',
    'No, lui presta attenzione a me': 'No, lei presta attenzione a me',
    'No, lui adora il cibo!': 'No, lei adora il cibo!',
    'Capisci lui meglio': 'Capisci lei meglio',
    'Sì, lui lo adora!': 'Sì, lei lo adora!',
    'Sì, lui vorrebbe essere accarezzato 24/7': 'Sì, lei vorrebbe essere accarezzata 24/7',
    'No, lui non ama essere toccato': 'No, a lei non piace essere toccata',
    'Sì, lui lo adora': 'Sì, lei lo adora',
    'Lui chiede di giocare con te?': 'Lei chiede di giocare con te?',

    Wedding: 'Matrimonio',
    Vacation: 'Vacanza',
    'Birth child': 'Nascita di un bambino',
    'Pet arrival': 'Nuovo arrivo dell’animale',
    Relocation: 'Trasferimento',
    'Personal Goal': 'Obiettivo personale',
    Other: 'Altro',
    Skip: 'Nessun evento speciale',

    // password errors
    'Passwords must match': 'Le password devono coincidere',
    'Password must contain at least 6 characters': 'La password deve contenere almeno 6 caratteri',
    'Current user already has password': "L'utente corrente ha già una password",
    'Please enter email of current user': 'Inserisci l’email dell’utente attuale',
    'Wrong E-mail or password': 'Email o password errati',

    // email errors
    'Sorry. Something went wrong. Try again later': 'Scusa. Qualcosa è andato storto. Riprova più tardi',
    'Please, type your valid E-mail. Field couldn’t be empty': 'Inserisci un’email valida. Il campo non può essere vuoto',
    'Please, type your valid E-mail': 'Inserisci un’email valida',
    'User with this email already exists': 'Esiste già un utente con questa email',

    // card errors
    'Error occurred. Insufficient funds': 'Si è verificato un errore. Fondi insufficienti',
    'Error occurred. Payment amount limit exceeded': 'Si è verificato un errore. Limite di pagamento superato',
    'Error occurred. Debit card is not supported': 'Si è verificato un errore. La carta di debito non è supportata',
    'Error occurred. Card brand is not supported': 'Si è verificato un errore. Il marchio della carta non è supportato',
    'Error occurred. Invalid CVV2 code': 'Si è verificato un errore. Codice CVV2 non valido',
    'Error occurred. Invalid card number': 'Si è verificato un errore. Numero di carta non valido',
    'Error occurred. Invalid expiration date': 'Si è verificato un errore. Data di scadenza non valida',
    'Error occurred. 3D secure verification failed': 'Si è verificato un errore. Verifica 3D Secure fallita',
    'Error occurred. Please try another card or payment method': 'Si è verificato un errore. Prova un’altra carta o metodo di pagamento',
    'Error occurred. Please try again or try another card/payment method': 'Si è verificato un errore. Riprova o prova un’altra carta/metodo di pagamento',

    promoCodeFinalPostfix: 'finale',

    monthShortNames: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],

    magic: {
        '100_text': '{name} Il piano di è pronto!'
    }
}

function parseLocalizations(map, result = {}, keyPrefix = '') {
    Object
      .entries(map)
      .forEach(([recordKey, value]) => {
          const resultKey = keyPrefix + recordKey;

          if (typeof value === 'string') {
              result[resultKey] = value;
          } else {
              parseLocalizations(value, result, resultKey + '.');
          }
      })

    return result;
}

const translations = {
    en: parseLocalizations(en),
    es: parseLocalizations(es),
    pt: parseLocalizations(pt),
    de: parseLocalizations(de),
    fr: parseLocalizations(fr),
    it: parseLocalizations(it),
};

/**
 *
 * @param {string} text
 * @param {string} language
 * @param {object|null} context
 * @returns {string}
 */
function translate(text, language, context = null) {
    const translationsMap = translations[language];
    if (!translationsMap) {
        return text;
    }

    const translated = translationsMap[text];

    if (context && translated) {
        return translated.replace(/\{([^}]+)}/g, (match, key) => {
            return context[key] || match;
        });
    }

    return translated || text;
}

export { translate };
